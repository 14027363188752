export interface QuotePartUpdateInput {
	attributes?: AttributeValueOutput[];
}
export interface AttributeValueOutput {
	attr_id: number;
	value: string;
}

export enum QuotePartField {
	mi6_quote_id = 'mi6_quote_id',
	mi6_quote_part_id = 'mi6_quote_part_id',
	sca_estimate_price = 'sca_estimate_price',
	sca_confidence = 'sca_confidence',
	sca_price_is_actual = 'sca_price_is_actual',
	attibute_check_passed = 'attibute_check_passed',
	df_status = 'df_status',
	df_price = 'df_price',
	serial_number = 'serial_number',
	jpn = 'jpn',
	mpn = 'mpn',
	customer_pn = 'customer_pn',
	region = 'region',
	moq = 'moq'
}

export interface QuotePartWithAttributes {
	[QuotePartField.attibute_check_passed]: boolean;
	[QuotePartField.df_price]: number;
	[QuotePartField.df_status]: string;
	[QuotePartField.jpn]: string;
	[QuotePartField.mi6_quote_id]: string;
	[QuotePartField.mi6_quote_part_id]: string;
	[QuotePartField.moq]: number;
	[QuotePartField.mpn]: string;
	[QuotePartField.region]: string;
	[QuotePartField.sca_confidence]: number | null;
	[QuotePartField.sca_estimate_price]: string | number | null;
	[QuotePartField.sca_price_is_actual]: boolean;
	[QuotePartField.serial_number]: number;

	attributes: AttributeValueOutput[];
}

export interface Supplier {
	id: string;
	name: string;
	country: string;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface QuotePart extends QuotePartWithAttributes {
	[key: string]: string | boolean | number | null | undefined | AttributeValueOutput[];
}
