import { gql } from 'apollo-angular';

export const GetAttributesConfiguration = gql`
	query GetAttributeConfigurations {
		getAttributeConfigurations {
			attributes {
				attr_id
				category
				field
				group
				name
				options
				order
				raw_part_field
				required
				source
				type
				uom
				variations {
					attr_id
					attr_variation_id
					options
					parent_attr_id
					parent_attr_variation_id
					parent_option_id
					required
					conditions {
						attr_id
						attr_option_id
					}
				}
			}
			options {
				attr_option_id
				name
			}
		}
	}
`;
