import { Injectable } from '@angular/core';
import { GroupsOrder } from '@constants/part-attributes.constant';
import { RawPartField } from '@enums/raw-part.enum';
import { AttributeConfiguration, AttributeConfigurations, AttributeType } from '@interfaces/part-attributes.interface';
import { patchState, signalState } from '@ngrx/signals';
import { groupBy } from 'lodash-es';

export interface ConfigMaps {
	optionsMap: Record<number, string>;
	rawPartAtrributesMap: Record<string, { raw_part_field: string; type: AttributeType }>;
	rawFieldsList: string[];
	rawPartFieldToNameMap: Record<string, string>;
	attrsGroupedBySection: Record<string, AttributeConfiguration[]>;
	fieldToAttrIdMap: Record<string, string>;
	attrIdToNameMap: Record<string, string>;
}

@Injectable({
	providedIn: 'root'
})
export class AttributesConfigStoreService {
	readonly state = signalState({
		attributesConfig: {} as AttributeConfigurations,
		configMaps: {} as ConfigMaps
	});

	/* eslint-disable @typescript-eslint/no-explicit-any */
	setAttributesConfig(attributesConfig: AttributeConfigurations) {
		const { attributes, options } = attributesConfig;
		const groupsOrder = GroupsOrder;
		const sortedConfigAttrs = [...attributes].sort((a: AttributeConfiguration, b: AttributeConfiguration) => {
			const indexA = groupsOrder.indexOf(a.group);
			const indexB = groupsOrder.indexOf(b.group);

			if (indexA === -1 && indexB === -1) return 0;
			if (indexA === -1) return 1;
			if (indexB === -1) return -1;

			return indexA - indexB;
		});
		const groupedBySection = groupBy(sortedConfigAttrs, 'group');
		const configMaps = {
			rawPartAtrributesMap: attributes
				.filter(a => a.raw_part_field)
				.reduce((acc, a) => ({ ...acc, [a.attr_id]: { field: a.field, raw_part_field: a.raw_part_field, type: a.type } }), {}) as any,
			optionsMap: options.reduce((acc, { attr_option_id, name }) => ({ ...acc, [attr_option_id]: name }), {} as Record<number, string>),
			attrsGroupedBySection: groupedBySection,
			rawPartFieldToNameMap: attributes
				.filter(a => a.raw_part_field)
				.reduce((acc, a: any) => ({ ...acc, [a.raw_part_field]: a.name }), {}) as any,
			rawFieldsList: Object.values(RawPartField),
			fieldToAttrIdMap: attributes.filter(a => a.field && a.attr_id).reduce((acc, a: any) => ({ ...acc, [a.field]: a.attr_id }), {}) as any,
			attrIdToNameMap: attributes.filter(a => a.attr_id && a.name).reduce((acc, a: any) => ({ ...acc, [a.attr_id]: a.name }), {}) as any
		};

		patchState(this.state, { attributesConfig, configMaps });
	}
}
